@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');
body{
    overflow: hidden !important;
    margin: 0;
    padding: 0;
    background: #F7FBFD !important;
}
a{
    text-decoration: none !important;
}
.ml-1 {
    margin-left: 0.25rem;
}
.mr-1 {
    margin-right: 0.25rem;
}
.ml-2 {
    margin-left: 0.5rem;
}
.ml-5{
    margin-left: 2.5rem;
}
.mr-2 {
    margin-right: 0.5rem;
}
.p-x-15{
    padding: 15px 0;
}
.mr-5 {
    margin-right: 5px;
}
.m-top-5{
    margin-top: 5px;
}
.p-l-50{
    padding-left: 50px !important;
}
.overflow-x-hidden{
    overflow-x: hidden !important;
}
/* splash screen start*/
.splash-screen{
    position: relative;
    width: 100%;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
    overflow: hidden;
}
.screen-top {
    position: absolute;
    width: 100%;
    height: auto;
    top: 237px;
}
.screen-middle {
    position: fixed;
    width: 100%;
    height: 312px;
    left: 196px;
    bottom: calc(100% - 586PX);
}
.screen-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.bottom-indicator{
    position: absolute;
    width: 134px;
    height: 5px;
    left: calc(50% - 134px/2 + 0.5px);
    bottom: 5px;
    background: #FFFFFF;
    border-radius: 100px;
}
/* splash screen end*/
/* Bottom nav start*/
.bottom-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 82px;
    background: #1672EC;
    border-radius: 24px 24px 0px 0px;
}
.nav-icon{
    /* width:24px;
    height: 24px; */
    padding: 0px 20px;
}
.nav-button{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #1672EC;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    border-radius: 24px;
    padding: 2px 10px;
    margin: 0 5px;
}
/* Bottom nav end*/
/* Login page start*/
.login-bg {
    /* background-color: #1672EC; */
    background-image: url("../image/login-background.png");
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    height: 494px;
}
.login-screen-top {
    position: absolute;
    width: 100%;
    height: 507px;
    left: 0px;
    top: -9px;
}
.login-bottom {
    background-color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: -64px;
    height: calc(100vh - 430px);
}
.login-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 35px;
    text-align: left;
    color: #1672EC;
}
.login-label{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    color: #4F4F4F;
    margin-bottom: 10px;
}
.login-form-input {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 19.5485px;
    line-height: 20px;
    text-align: left;
    color: #1672EC !important;
    width: 100%;
    border: 0;
    border-bottom: 1.07937px solid #4F4F4F;
    background: #fff !important;
}
.login-form ::placeholder{
    color: #1672EC !important;
}
.form-control:focus{
    box-shadow: none !important;
}
.login-form{
    position: relative;
}
.login-form-input:active,
.login-form-input:focus {
    outline: none !important;
}
.get-otp-text{
    position: absolute;
    right: 10px;
    font-size: 15px;
    top: 33px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    text-align: right;
    color: #1672EC;
    text-decoration: underline;
}
.show-password {
    position: absolute;
    right: 10px;
    font-size: 17px;
    bottom: 50px;
}
.login-btn {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 17.851px !important;
    line-height: 21px !important;
    background-color: #1672EC !important;
    color: #FFFFFF !important;
    box-shadow: 0px 4.82682px 8.04469px rgb(22 114 236 / 20%);
    border-radius: 12px !important;
    padding: 10px 35px !important;
}
.bottom-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13.9487px;
    line-height: 14px;
    text-align: center;
    color: #4F4F4F;
}
.bottom-indicator-dark{
    position: absolute;
    width: 134px;
    height: 5px;
    left: calc(50% - 134px/2 + 0.5px);
    bottom: 8px;
    background: #000000;
    border-radius: 100px;
}
/* Login page end*/
/* Home page Start */
.home-intro-section{
    background-image: url("../image/frame1.png"), linear-gradient(to right, #1e91f0 , #1661ef);
    background-repeat: no-repeat;
    background-position: bottom;
    background-blend-mode: soft-light;
    /* height: 374px; */
    padding-left: 24px;
    padding-right: 24px;
    overflow-x: hidden !important;
}
.user-name {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 28px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #FFFFFF;
    padding-top: 24px;
}
.notification-wrapper {
    background: #FFFFFF;
    border: 1px solid #E5E9EF;
    border-radius: 12px;
    width: 40px;
    height: 40px;
    margin-top: 20px;
    position: relative;
}
.notification-icon {
    width: 18px;
    height: 21.6px;
    position: absolute;
    top: 9px;
    left: 11px;
}
.notification-status{
    position: absolute;
    left: 75.98%;
    right: 21.98%;
    top: 4.14%;
    bottom: 95.39%;
    background: #7ED321;
    width: 11.5px;
    height: 11.5px;
    border-radius: 50%;
}
.other-icon-wrapper{
    margin-left: 20px;
}
.other-icon {
    margin-top: 24px;
}
.user-detail{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #CEE3FF;
}
.bottom-line{
    border: 0.68984px solid rgba(138, 189, 255, 0.4);
}
.select-date{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}
.calender-title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}
.swiper {
    width: 100%;
    height: 100%;
    
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    padding-bottom: 30px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .day-list{
   
    width: 48px;
height: 71px;
   
  }
  .active-list{
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    /* Navigation/Light Shadow */
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    
  }

  .current-day{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    /* Common / #1672EC */
    padding-top: 13px;
    margin-bottom: 8px;
    color: #fff;
  }
  .current-date{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    /* Common / #fff */

    color: #fff;
  }
  .day-active{
    color:#1672EC !important
  }
  
  .date-active{
    color:#1672EC !important
  }
  .mySwiper .swiper-pagination-bullet-active {
   opacity: 1 !important;
    
}
.mySwiper .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width,var(--swiper-pagination-bullet-size,83px)) !important;
    height: var(--swiper-pagination-bullet-height,var(--swiper-pagination-bullet-size,4px)) !important;
    display: inline-block;
    border-radius: 30px;
    background: #f8f9fa;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0);
   
}
.search-box input {
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 16px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    padding-left: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #D4D4D4;
}
.search-box {
    position: relative;
    z-index: 1;
    margin: 0 10px;
    box-shadow: 0px 5px 17px rgb(0 0 0 / 10%);
    border-radius: 16px;
}
.search-icon {
    position: absolute;
    bottom: 16px;
    left: 13px;
}
.services-section {
    background: #F7FBFD;
    border-radius: 25px 25px 0px 0px;
    height: calc(100vh - 236px);
    position: relative;
    top: -43px;
    padding-top: 50px;
    padding-left: 24px;
    padding-right: 24px;
    overflow-y: auto;
}
.services-section-home {
    background: #F7FBFD;
    border-radius: 25px 25px 0px 0px;
    height: calc(100vh - 315px);
    position: relative;
    top: -43px;
    padding-top: 50px;
    padding-left: 24px;
    padding-right: 24px;
    overflow-y: auto;
}
.services-section .nav-link {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #9A9A9A;
}
.services-section .nav-link.active {
    color: #1672EC !important;
}
.services-section .nav-link.active {
    color: #1672EC !important;
    border-bottom: 3px solid #1672EC !important;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background: transparent !important;
    border: 0;
}
.service-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1F1F1F;
    margin-top: 8px;
}
.service-icon{
    width: 36px;
    height: 36px;
    text-align: center;
    margin: 0 auto;
}
.service-bottom-line{
    border: 1px solid rgba(162, 192, 212, 0.5);
    margin: 25px 0;
}
.title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
.view-all-text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #707070;
}
.event-content-wrapper {
    background: #FFFFFF;
    border: 1px solid rgba(162, 192, 212, 0.5);
    box-shadow: 0px 5.96859px 17.9058px rgb(0 0 0 / 10%);
    border-radius: 16px;
    padding: 8px;
    margin-bottom: 10px;
    position: relative;
}
.event-descripion {
    margin-left: 8px;
}
.event-descripion h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    padding-top: 5px;
}
.event-descripion p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    color: #9A9A9A;
    margin-bottom: 0;
}
.event-right-arrow{
    position: absolute;
    right: 12px;
    top: 21px;
}
.event-image {
    margin-right: 6px;
}
.event-image img{
    width: 56px;
    height: 56px;
    border-radius: 8px;
}

/* Inner Page Css Start */
.title-inner{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
    color: #1672EC;
}
.sub-title-inner{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #4F4F4F;
    margin-bottom: 0;
}
.attendance-dropdown .form-select {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1672EC;
    border: 1px solid #E4E4E4;
    border-radius: 16px;
    height: 50px;
}
.attendance-dropdown .form-select:focus{
    box-shadow: none;
}
.attendance-bottom-line{
    border: 1px solid rgba(162, 192, 212, 0.5);
    margin: 18px 0;
}
/* .attendance-list-view{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 301.39px;
    height: 40px;
    flex: none;
    order: 2;
    flex-grow: 0;
} */
.attendance-list-view td {
    border-bottom-width: 0 !important;
}
/* .attendance-list-view tr {
    text-align: right;
} */
td.title{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14.3478px;
    line-height: 22px;
    text-align: justify;
    color: #4F4F4F;
}
td.title-underline{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14.3478px;
    line-height: 22px;
    text-align: center;
    color: #4F4F4F;
    text-decoration: underline;
}

.attendance-list-view .form-check-input {
    font-size: 25px;
    border-radius: 50% !important;
    border: 2px solid #2C3E50;
    margin-top: 0 !important;
}

.attendance-list-view .form-check-input:focus{
    box-shadow: none !important;
}
/* .attendance-list-view {
    padding-bottom: 40px;
} */
.attendance-btn{
    box-shadow: 0px 4.82682px 8.04469px rgb(22 114 236 / 20%);
    border-radius: 12px !important;
    padding: 9px 25px !important;
    font-size: 20px !important;
    width: 40%;
}
.attendance-btn:focus{
    box-shadow: none !important;
}
.attendance-dropdown select {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1672EC;
    border: 1px solid #E4E4E4;
    border-radius: 16px;
    height: 50px;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
}
.attendance-dropdown select:focus-visible{
    border: 1px solid #1672ec !important;
    outline: 0;
}
.attendance-dropdown textarea {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    color: #9A9A9A;
    border-radius: 16px;
    padding: 5px 15px;
}


input[type="radio"]:checked{
    background-image : url('../image/svg/tick.svg') !important;
    background-color: #fff !important;
    background-size: auto;
    border: 2px solid #1672EC;
  }
  
  .message-content-wrapper {
    border-bottom: 1px solid rgba(162, 192, 212, 0.5);
}

.user-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}


.user-detail h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
/* identical to box height, or 123% */
    text-align: justify;

/* Gray/#4F4F4F */
    color: #4F4F4F;
    margin-bottom: 0;
    margin-top: 4px;
}

.user-detail p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
/* identical to box height, or 158% */
    text-align: justify;
    color: #9A9A9A;
}
.message-content-wrapper {
    border-bottom: 1px solid rgba(162, 192, 212, 0.5);
    position: relative;
    margin-top: 10px;
}
.message-icon {
    border-left: 1px solid rgba(162, 192, 212, 0.5);
    border-right: 1px solid rgba(162, 192, 212, 0.5);
    height: 36px;
    margin-top: 10px;
    position: absolute;
    right: 0;
    padding: 0 10px;
}
.chat-icon {
    position: fixed;
    bottom: 91px;
    background: #1672ec;
    padding: 16px;
    width: 60px;
    height: 60px;
    border-radius: 16px;
    z-index: 999;
    right: 20px;
}
.add-chat {
    position: absolute;
    right: 8px;
}
.chat-day{
width: 101px;
height: 27px;
margin: 0 auto;
background: #1672EC;
border-radius: 10px;
}
.chat-day p {
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    padding-top: 5px;
}
.chat-message-left {
    float: left;
}
.chat-message-left p {
    padding: 10px 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #1672EC;
    margin-bottom: 5px;
    background: #E9E9E9;
    border-radius: 40px 40px 40px 0px;
    margin-top: 15px;
}
.chat-message-right {
    float: right;
}
.chat-message-right p {
    background: #1672EC;
    border-radius: 40px 0px 40px 40px;
    margin-top: 15px;
    padding: 10px 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #fff;
    text-align: right;
    margin-bottom: 5px;
}
.chat-date-time P {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #9A9A9A;
    clear: both;
}
.text-right{
    text-align: right;
}
.chat-input-box {
    position: relative;
    margin-top: 20px;
}
.chat-input-box input {
    height: 60px;
    width: calc(100% - 48px) !important;
    position: fixed;
    bottom: 10px;
    border-radius: 30px;
    background: #1672ec;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    color: #FFFFFF;
    padding-left: 40px;
}
.chat-input-box input::placeholder{
    color: #fff;
}
.chat-input-box input:focus{
    background: #1672ec;
    color: #fff;

}
.upload-attacchment {
    position: fixed;
    bottom: 29px;
    z-index: 999;
    left: 38px;
}
.btn-send {
    position: fixed;
    right: 32px;
    bottom: 15px;
    border-radius: 50% !important;
    background-color: #165EBC !important;
    width: 50px;
    height: 50px;
}
.btn-send:hover{
    border: none !important;
}
.btn-send img {
    padding: 5px;
}
.example-custom-input {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4F4F4F;
    background: #FFFFFF;
    border: 0.662362px solid #ced4da;
    border-radius: 12px;
    padding: 6px 4px;
}
.calender-label{
    font-weight: 500;
    font-size: 14.3478px;
    line-height: 22px;
    font-family: 'Inter';
    font-style: normal;
    text-align: justify;
    color: #4F4F4F;
    margin-bottom: 2px;
}

.drop-file-input {
    position: relative;
    border: 1px solid #E4E4E4;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}

.drop-file-input__label p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-top: 10px;
    color: #9A9A9A;
}
.drop-file-input__label {
    text-align: center;
    padding: 30px 0;
}
.drop-file-preview {
    margin-top: 10px;
}

.drop-file-preview p {
    font-weight: 500;
}

.drop-file-preview__title {
    margin-bottom: 10px;
}

.drop-file-preview__item {
    position: relative;
    display: flex;
    margin-bottom: 10px;
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 10px;
}

.drop-file-preview__item img {
    width: 50px;
    margin-right: 10px;
    height: 50px;
    margin-top: 10px;
}

.drop-file-preview__item__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 10px;
}

.drop-file-preview__item__del {
    background-color: #4F4F4F;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -6px;
    top: 7px;
    transform: translateY(-50%);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;
    color: #fff;
    font-size: 19px;
    padding: 0px 10px;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
    opacity: 1;
}
.skip-text{
    position: fixed;
    color: #fff;
    bottom: 88px;
    font-size: 26px;
    left: 42%;
}
.skip-text a, .login-btn a{
    color:#fff;
}